<template>
  <div>
    <slot name="title">
      <VTitle :title="$t('app.attestations', 2)" class="route-title" />
    </slot>

    <div>
      <AttestationCard :resource="data" hide-view-button class="mb-8" />

      <AttestationSubmissions :submissions="data.otherSubmissions" />
    </div>
  </div>
</template>

<script>
// Components
import AttestationCard from "@/views/learning/attestations/AttestationCard";
import AttestationSubmissions from "@/views/learning/attestations/AttestationSubmissions";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VTitle,
    AttestationSubmissions,
    AttestationCard
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
