<template>
  <VList :headers="headers" :rows="rows">
    <template #title>
      {{ $t("app.all_submissions") }}
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.files>
      <FileItems :files="item.files" />
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import FileItems from "@/components/FileItems";

export default {
  components: {
    VList,
    FileItems
  },
  props: {
    submissions: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { formatDate } = useDisplay();

    // Constants
    const headers = [
      {
        text: t("app.submitted_on"),
        value: "displayed_submitted_on"
      },
      {
        text: t("app.submitted_by"),
        value: "displayed_submitted_by"
      },
      {
        text: t("app.files"),
        value: "files"
      },
      {
        text: t("app.status"),
        value: "status"
      }
    ];

    // Computed
    const rows = computed(() => {
      return props.submissions.map(submission => ({
        ...submission,
        displayed_submitted_on: formatDate({ date: submission.submitted_on }),
        displayed_submitted_by: `${submission.submitter.firstname} ${submission.submitter.lastname}`
      }));
    });

    return {
      headers,
      rows
    };
  }
};
</script>
